import React from "react";
import Leftnav from "../DashBoard/Leftnav";
// import '../../assets/style/Prodect.css'
function BodyLeft() {
    return (
        <>

            <div className="row py-2 ">
                <div className="col-6">
                    <h3>Customer Info</h3>
                </div>
                <div className="col-6 pt-2">
                    <h5>Order Id : <span className="border border-3 rounded px-3 text-secondary">101</span></h5>

                </div>
            </div>

{/* email-mobile */}

            <form>
                <div class="form-row row">
                    <div class="form-group col-6">
                        <label for="inputEmail4" className="py-1">Email</label>
                        <input type="email" class="form-control" id="inputEmail4" placeholder="Email" />
                    </div>
                    <div class="form-group col-6">
                        <label for="mobile" className="py-1">Mobile</label>
                        <input type="text" class="form-control" id="mobile" placeholder="Mobile" />
                    </div>
                </div>

            </form>


{/* category */}

            <div className="row py-4 ">
                <div className="col-4">
                    <label for="category" className="py-1">Category List</label>
                    <select class="form-select" aria-label="Default select example" id="category">
                        <option selected>Category List</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div>
{/* quantity */}
                <div className="col-3">
                    <div className="form-outline">
                        <label for="quantity" className="py-1">Quantity</label>
                        <input type="number" id="typeNumber" className="form-control" />

                    </div>
                </div>
{/* Price */}

                <div className="col-3">
                <div className="form-outline">
                        <label for="Price" className="py-1">Price</label>
                        <input type="number" id="typeNumber" className="form-control" />
                    </div>
                </div>

{/* add- button */}

                <div className="col-2">
                    <div className="p-1"></div>
                <button type="button" class="btn btn-success w-75 m-0 my-4 p-1">ADD</button>
                </div>



                {/* Add-item */}

                <div className="row">
                    <div className="col-12">
                        <h3>Add Item</h3>
                    </div>
                </div>
      

      <div className="scrollable-table-container">
      <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Fruit</th>
      <th scope="col">Quantity</th>
      <th scope="col">Price</th>
      <th scope="col">Delete</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
      <td className=""><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">4</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">5</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">6</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">7</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">8</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">9</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">10</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">11</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">12</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">13</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">14</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
    <tr>
      <th scope="row">15</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
      <td><i class="bi bi-trash3"></i></td>
    </tr>
  </tbody>
</table>
      </div>





            </div>





        </>
    )
}
export default BodyLeft