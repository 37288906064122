import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Logo from "../../assets/img/Login/login logo 2.png"
import Logotext from "../../assets/img/Login/logotext-Photoroom 2.png"
import { Link } from 'react-router-dom';
function Login(){

    const [values, setValues] = useState({
        visitorEmail: '',
        visitorPassword: ''
      });
    //   const navigate = useNavigate();
    
      const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form values:', values);
      }
    return(
        <>
              <div className="login-wrapper d-flex align-items-center justify-content-center text-center bg-white h-100">
        <div className="background-shape "></div>
        <div className="h-100 w-50 pt-3">
          <div className="row justify-content-center pt-3">
            <div className="col-10 col-lg-8">
              <img className="big-logo w-50 " src={Logo} alt="logo"  />
              <div className='pt-0'>
                <img className="big-logo-title w-25 h-25" src={Logotext} alt="logotext" />
              </div>
              <div className="register-form  border-dark p-3 border-5 rounded-5 py-5">
                <form onSubmit={handleSubmit} className=' '>
                  <div className="form-group text-start mb-4">
                    <span className=''>Username</span>
                    <label htmlFor="username"><i className="bi bi-person-circle text-secondary ps-2"></i></label>
                    <input
                      className="form-control my-3"
                      id="username"
                      type="email"
                      value={values.visitorEmail}
                      onChange=""
                      placeholder="info@example.com"
                      required
                    />
                  </div>
                  <div className="form-group text-start mb-4">
                    <span className=''>Password</span>
                    <label htmlFor="password"><i className="bi bi-key text-secondary ps-2"></i></label>
                    <input
                      className="form-control  my-3"
                      id="password"
                      type="password"
                      value={values.visitorPassword}
                      onChange=""
                      placeholder="Enter Password"
                      required
                    />
                  </div>
               <Link to={'/Dashboard'} >  <button className="btn btn-lg w-50 border border-5 rounded-5 text-dark" type="submit">Log In</button></Link>
                  
                </form>
              </div>
              <div className="login-meta-data mt-2 pb-2 ">
                <a className="forgot-password d-block mt-3 mb-1" href="#">Forgot Password?</a>
                <p className="mb-0">Don't have an account?<a className="mx-1" href="#">Register Now</a></p>
              </div>
              {/* <div className="view-as-guest mt-3">
                <a className="btn btn-primary btn-sm" href="https://designing-world.com/suha-3.1.0/home.html">View as guest<i className="ps-2 ti ti-arrow-right"></i></a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
        </>
    )
}
export default Login