import React from "react";
import Invoice from "../Invoice/Invoice";

function BodyRight() {
    return (
       <>
<Invoice />
       </>
    );
}

export default BodyRight;
