import React from "react";
import Header from "./Header";
import Body from "./Body";

function Prodect(){
    return(
        <>
        <Body />
        </>
    )
}
export default Prodect