import React from "react";

function Invoice(){
    return(
        <>
         <div className="container">
        <div className="card">
            <div className="card-body">
                <div className="container mb-2 mt-3">
                    <div className="row d-flex align-items-baseline">
                        <div className="col-xl-9">
                            <p style={{ color: '#7e8d9f', fontSize: '20px' }}>
                                Invoice <span></span> <strong>ID: #123-123</strong>
                            </p>
                        </div>
                        {/* <div className="col-xl-3 float-end">
                            <a
                                className="btn btn-light text-capitalize border-0"
                                style={{ '--mdb-ripple-color': 'dark' }}
                            >
                                <i className="fas fa-print text-primary"></i> Print
                            </a>
                            <a
                                className="btn btn-light text-capitalize"
                                style={{ '--mdb-ripple-color': 'dark' }}
                            >
                                <i className="far fa-file-pdf text-danger"></i> Export
                            </a>
                        </div> */}
                        <hr />
                    </div>

                    <div className="container">
                        <div className="col-md-12">
                            <div className="text-center">
                                <i className="fab fa-mdb fa-4x ms-0" style={{ color: '#5d9fc5' }}></i>
                                <p className="pt-0">MSR.com</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <ul className="list-unstyled">
                                    <li className="text-muted fw-bold">To: <span style={{ color: '#5d9fc5' }}>Mita</span></li>
                                    <li className="text-muted">Nehruji Nagar,<br></br> Dindigul, 624001</li>
                                    {/* <li className="text-muted">State, Country</li> */}
                                    <li className="text-muted"><i className="fas fa-phone"></i>+91 8098111311</li>
                                </ul>
                            </div>
                            <div className="col-6">
                                {/* <p className="text-muted">Invoice</p> */}
                                <ul className="list-unstyled">
                                    {/* <li className="text-muted"><i className="fas fa-circle" style={{ color: '#84B0CA' }}></i> <span className="fw-bold">ID:</span>#123-456</li> */}
                                    <li className="text-muted"><i className="fas fa-circle" style={{ color: '#84B0CA' }}></i> <span className="fw-bold">Date: </span>Jun 23,2021</li>
                                    <li className="text-muted"><i className="fas fa-circle" style={{ color: '#84B0CA' }}></i> <span className="me-1 fw-bold">Status:</span><span className="badge bg-warning text-black fw-bold">Unpaid</span></li>
                                </ul>
                            </div>
                        </div>

                        <div className="row my-2 mx-1 justify-content-center invoicetable">
                            <table className="table table-striped table-borderless">
                                <thead style={{ backgroundColor: '#84B0CA' }} className="text-white">
                                    <tr>
                                        <th scope="col">Item</th>
                                        <th scope="col">Fruit</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Fruit 1</td>
                                        <td>4</td>
                                        <td>$200</td>
                                        <td>$800</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Fruit 2</td>
                                        <td>1</td>
                                        <td>$10</td>
                                        <td>$10</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td>Fruit 3</td>
                                        <td>1</td>
                                        <td>$300</td>
                                        <td>$300</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">4</th>
                                        <td>Fruit 4</td>
                                        <td>1</td>
                                        <td>$300</td>
                                        <td>$300</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">5</th>
                                        <td>Fruit 5</td>
                                        <td>1</td>
                                        <td>$300</td>
                                        <td>$300</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">6</th>
                                        <td>Fruit 6</td>
                                        <td>1</td>
                                        <td>$300</td>
                                        <td>$300</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">7</th>
                                        <td>Fruit 7</td>
                                        <td>1</td>
                                        <td>$300</td>
                                        <td>$300</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">8</th>
                                        <td>Fruit 8</td>
                                        <td>1</td>
                                        <td>$300</td>
                                        <td>$300</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="row mb-0">
                            {/* <div className="col-xl-8">
                                <p className="ms-3">Add additional notes and payment information</p>
                            </div> */}
                            <div className="col-xl-12">
                                <ul className="list-unstyled">
                                    <li className="text-muted ms-3"><span className="text-black me-4">SubTotal</span>$1110</li>
                                    <li className="text-muted ms-3 "><span className="text-black me-4">Tax(15%)</span>$111</li>
                                </ul>
                                <p className="text-black float-start">
                                    <span className="text-black me-3"> Total Amount</span>
                                    <span style={{ fontSize: '25px' }}>$1221</span>
                                </p>
                            </div>
                        </div>
                        <hr />
                        <div className="row py-1 mb-0">
                            <div className="col-4 p-0">
                            <button type="button" class="btn btn-primary inbtn p-0 py-1">Print</button>
                            </div>
                            <div className="col-4 p-0">
                            <button type="button" class="btn btn-warning inbtn p-0 py-1">Save</button>
                            </div>
                            <div className="col-4 p-0">
                            <button type="button" class="btn btn-danger inbtn py-1 p-0">Submit</button>
                            </div>
                        </div>
                        {/* <div className="row mt-0">
                            <div className="col-xl-10 text-center  p-0">
                                <p>Thank you for your purchase</p>
                            </div>


                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        </div>

        </>
    )
}
export default Invoice