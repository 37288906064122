import React, { useState } from 'react';
import Button from '../../assets/js/Dashleft';
import '../../assets/style/DashLeftnav.css';
import Logo from "../../assets/img/Login/login logo 2.png"
import Logotext from "../../assets/img/Login/logotext-Photoroom 2.png"
import { Link } from 'react-router-dom';
function Leftnav() {
    const [activeButton, setActiveButton] = useState('dashboard'); 

    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };

    return (
        <>
        
        <div className='col-2 '>
            {/* Main Navigation */}
            
            <header className=''>
                {/* Sidebar */}
                <nav id="sidebarMenu" className="collapse d-lg-block sidebar collapse leftnav pt-2">
                    <div className="position-sticky">
                   
                        <div className="list-group list-group-flush mx-3 mt-0 gap-3">
                        <img src={Logo} alt='logo'/>
                            <Button className=""
                                type={activeButton === 'dashboard' ? 'primary' : 'default'}
                                onClick={() => handleButtonClick('dashboard')}
                            >
                                <i className="bi bi-tachometer-alt fa-fw me-3"></i><span>Main dashboard</span>
                            </Button>
                            <Link to={'/Prodect'}> 
                         <Button 
                                type={activeButton === 'traffic' ? 'primary' : 'default'}
                                onClick={() => handleButtonClick('traffic')}>
                                     
                                <i className="fas fa-chart-area fa-fw me-3"></i><span>Category</span>
                            </Button></Link>
                            <Button
                                type={activeButton === 'password' ? 'primary' : 'default'}
                                onClick={() => handleButtonClick('password')}
                            >
                                <i className="fas fa-lock fa-fw me-3"></i><span>Pos</span>
                            </Button>
                            <Button
                                type={activeButton === 'analytics' ? 'primary' : 'default'}
                                onClick={() => handleButtonClick('analytics')}
                            >
                                <i className="fas fa-chart-line fa-fw me-3"></i><span>Management</span>
                            </Button>
                            <Button
                                type={activeButton === 'seo' ? 'primary' : 'default'}
                                onClick={() => handleButtonClick('seo')}
                            >
                                <i className="fas fa-chart-pie fa-fw me-3 text center"></i><span className='text-center'>Emp Management</span>
                            </Button>
                        </div>
                    </div>
                    <div className='mt-5 pt-5'> </div>
                    <div className='pt-5'> </div>
                    <div className='text-center mt-5'>
                    <img src={Logotext} alt='logofooter' className='w-50'></img>
                    </div>
                </nav>
                {/* Sidebar */}
                {/* Navbar */}
                <nav id="main-navbar" className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
                    {/* Container wrapper */}
                    <div className="container-fluid">
                        {/* Other navbar items */}
                    </div>
                    {/* Container wrapper */}
                    
                </nav>
                {/* Navbar */}
                
            </header>
            {/* Main Navigation */}
            {/* Main layout */}
            <main style={{ marginTop: '58px' }}>
                <div className="container pt-4"></div>
            </main>
            {/* Main layout */}
            </div>
        </>
    );
}

export default Leftnav;
