import React from "react";
import BodyLeft from "./BodyLeft";
import BodyRight from "./BodyRight";
import Header from "./Header";
import Leftnav from "../DashBoard/Leftnav";

function Body(){
    return(
        <>

        <div className="row p-0 m-0 mt-3">
        <div className="col-2">
        <Leftnav />
        </div>

       <div className="col-10">
        <div className="row">
            <div className="col-7 border-3 border-end px-3">
                <BodyLeft />
            </div>
            <div className="col-5">
            <BodyRight />
            </div>
        </div>
       </div>

        </div>

        </>
    )
}
export default Body