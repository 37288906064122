import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Login from './Components/Login/Login';
import Prodect from './Components/Prodect/Prodect';
import DashBoard from './Components/DashBoard/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../src/assets/style/Prodect.css'

function App() {
  return (
    <>
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/Dashboard' element={<DashBoard />} />
      <Route path='/Prodect' element={<Prodect />} />
    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
